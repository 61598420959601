
.services-content {
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px 0px;
    font-size: 20px;
    font-family: 'serif';
    justify-self: center;
    color: #000;
    font-size: 1.3rem;
    font-weight: bold;
    letter-spacing: 2px;
    word-wrap: break-word;
    text-align: justify;
    line-height: 26px;
}

.services {
  display: grid;
  /* grid-template-columns: 100%; */
  grid-template-rows: auto;
  grid-template-areas:
    "service-1"
    "service-2"
    "service-3"
    "service-4"
    "service-5"
    "service-6"
    "service-7"
    "service-8"
    "service-9"
    "service-10"
    "service-11"
    "service-12";
  justify-content: center;
  padding: 20px 0px;
}

.service {
    border-radius: 20px;
    background-color: #fcfcfc;
    margin: 10px 5px;
    padding: 30px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

.cost-link {
    text-decoration: underline;
    color: #2e333a;
    font-weight: 600;
    cursor: pointer;
    font-size: 30px;
}

/* .service-1 {
    background-color: #22a7f0;
}

.service-2 {
    background-color: #63bff0;
}

.service-3 {
    background-color: #a7d5ed;
}

.service-4 {
    background-color: #e4bcad;
}

.service-5 {
    background-color: #df979e;
}

.service-6 {
    background-color: #d7658b;
}

.service-7 {
    background-color: #d2980d;
}

.service-8 {
    background-color: #a57c1b;
}

.service-9 {
    background-color: #a57c1b;
}

.service-10 {
    background-color: #9080ff;
}

.service-11 {
    background-color: #776bcd;
}

.service-12 {
    background-color: #5e569b;
} */

.cost-content {
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px 0px;
  font-size: 20px;
  font-family: 'serif';
  justify-self: center;
  /* color: #4d5665; */
  color: #000;
  font-size: 1.5rem;
  font-weight: 400;
  word-wrap: break-word;
  text-align: justify;
  line-height: 26px;
  text-align: center;
}

@media only screen and (min-width: 800px) {
    .services {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-areas:
            "service-1 service-2"
            "service-3 service-4"
            "service-5 service-6"
            "service-7 service-8"
            "service-9 service-10"
            "service-11 service-12";
        font-size: 25px;
        padding: 20px 20px;
    }

    .service {
        margin: 10px 30px;
        padding: 30px 20px;
    }

    .cost-content {
        font-size: 1.8rem;
    }

    .counselling_sessions {
        font-size: 2.5rem;
    }
}

@media only screen and (min-width: 1450px) {
    .services {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-template-areas:
            "service-1 service-2 service-3" 
            "service-4 service-5 service-6"
            "service-7 service-8 service-9" 
            "service-10 service-11 service-12";
        padding: 20px 20px;
    }
}

/*
increase contact form size
*/