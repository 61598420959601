@keyframes down-enter {
  0% { clip-path: inset(0 0 100% 0); opacity: 0;}
  20% { clip-path: inset(0 0 100% 0); opacity: 0;}
  50% { clip-path: inset(0); opacity: 1;}
}

.online-therapy {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas:
    "online-therapy-title"
    "online-therapy-description"
    "online-therapy-description2"
    "online-therapy-read-more";
  /* background: linear-gradient(90deg, rgba(197,226,197,0.5942752100840336) 0%, rgba(197,226,197,1) 20%, rgba(197,226,197,1) 50%, rgba(197,226,197,1) 80%, rgba(197,226,197,0.5998774509803921) 100%); */
  /* background-color: #70b9838c; */
  background-color: #70b9835e;
  
  padding: 20px 0px;
}

.online-therapy-animation {
  animation: down-enter 3s ease;
}

.online-therapy-title {
  grid-area: online-therapy-title;
  justify-self: center;
  font-size: 2.5rem;
  font-family: "Archivo Black";
  font-weight: 400;
  color: #1A213B;
  word-wrap: break-word;
  margin: 0px 10px;
  text-align: center;
}

.online-therapy-description {
  grid-area: online-therapy-description;
  font-family: 'serif';
  justify-self: center;
  color: #4d5665;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 44px 30px 0px 30px;
  word-wrap: break-word;
  text-align: center;
}

.online-therapy-description-number {
  font-size: 1.3rem;
}

.online-therapy-description2 {
  grid-area: online-therapy-description2;
  font-family: 'serif';
  margin-top: 40px;
  margin-left: 30px;
  margin-right: 30px;
  text-align: center;
  color: #4d5665;
  font-size: 1.2rem;
}

.online-therapy-read-more {
  grid-area: online-therapy-read-more;
  width: 200px;
  text-align: center;
  align-content: center;
  justify-self: center;
  padding: 20px 40px 20px 40px;
  color: #ffffff !important;
  background-color: #243f30 !important;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 25px;
  letter-spacing: 3px;
  cursor: pointer;
}

@media only screen and (min-width: 800px) {

  .online-therapy-title {
    font-size: 3.75rem;
  }
}