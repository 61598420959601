.header {
  /* display: grid; */
  /* grid-template-columns: 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%; */
  /* grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: 
    "header"; */
    /* "title title"; */
    /* "address address address . phoneText phoneText phoneText phoneText"; */
    /* "address address address . contact contact contact contact"; */
  
  background-color: #fcfcfc;
  padding-bottom: 15px;
  padding-top: 10px;
  display: grid;
  grid-template-columns: .5fr 1fr .5fr;
  grid-template-areas:
    'logo logo logo logo'
    'title title title title'
    'address address . contact';
}

@keyframes zoom-in-zoom-out-logo {
  0% {
    transform: scale(0.3, 0.3);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes zoom-in-zoom-out-text {
  0% {
    opacity: 0;
  }
  30% {
    transform: scale(0.3, 0.3);
    opacity: 0;
  }
  80% {
    transform: scale(1, 1);
  }
}

@keyframes down-enter {
  0% { clip-path: inset(0 0 100% 0); opacity: 0;}
  20% { clip-path: inset(0 0 100% 0); opacity: 0;}
  80% { clip-path: inset(0); opacity: 1;}
}

.menuIcon {
  position: absolute;
  top: 15px;
  left: 15px;

  .MuiSvgIcon-root {
    font-size: 3rem;
  }
}

.logo {
  grid-area: logo;
  color: white;
  justify-self: center;
  cursor: pointer;
}

.logo-animation {
  animation: zoom-in-zoom-out-logo 2s ease;
}
.title {
  grid-area: title;
  justify-self: center;
  text-align: center;
  font-size: 50px;
  /* font-size: 3.5rem; */
  font-family: 'Merienda',sans-serif;
  font-weight: 500;
  color: #1E2A42;
  word-break: break-all;
  cursor: pointer;
}

.title-animation {
  animation: 5s down-enter;
}

.address {
  grid-area: address;
  justify-self: flex-start;
  margin-top: 20px;
  margin-left: 10px;
  font-size: 1.1rem;
}

.address > a {
  text-decoration: none;
  color: #000;
}

.address-animation {
  animation: 5s zoom-in-zoom-out-text;
}

.phoneIcon {
  grid-area: phoneIcon;
  font-size: 2rem;
  justify-self: flex-end;
  animation: 5s zoom-in-zoom-out-text;
}

.test {
  grid-area: contact;
  display: grid;
  align-self: end;
  justify-content: end;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.phoneText2 {
  text-align: end;
}

.contact {
  /* grid-area: contact; */
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas: 
    "emailText"
    "phoneText";
}

.contact-animation {
  animation: 5s zoom-in-zoom-out-text;
}

/* .phoneText {
  grid-area: phoneText;
  justify-self: flex-end;
  align-self: end;
  padding-right: 15px;
  font-size: 1.4rem;
  .MuiSvgIcon-root {
    font-size: 1.3rem;
  }
}*/

.phoneText {
  text-align: end;
  font-size: 1.4rem;
  padding-top: 15px;
  padding-right: 15px;
  .MuiSvgIcon-root {
    font-size: 1.3rem;
  }
}

.phoneText > a {
  text-decoration: none;
  color: #000;
}

.emailText > a {
  padding-right: 15px;
  font-size: 1.1rem;
  color: black;
}

.emailText2 {
  padding-right: 15px;
}

@media only screen and (min-width: 800px) {
  

  .address {
    font-size: 1.5rem;
  }

  .emailText > a {
    font-size: 1.5rem;
  }

  .phoneText {
    font-size: 1.7rem;
  }
}

@media only screen and (min-width: 1000px) {
  .header{
    grid-template-columns: .5fr 1fr .5fr;
    grid-template-areas:
    '. logo .'
    'address title contact'
    ;
    /* 'address title title title title contact'
    'menu main main main right right'; */
    /* 'address address address contact contact contact'; */
  }

  .logo > img {
    width: 250px;
  }

  .title {
    font-size: 76px;
  }

  .address {
    display: flex;
    align-items: flex-end;
  }

  .emailText2 > a {
    font-size: 1.4rem;
    color: black;
  }
}