.aboutYourTherapist {
  /* display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas:
    "testimonial-title"
    "testimonial-1"
    "testimonial-2"
    "testimonial-3"
    "testimonial-4"; */
  justify-content: center;
  /* background-color: rgba(56, 54, 54, 0.836); */
  padding: 20px 20px;
  /* background-image: url(../../images/ruth.jpg);
  background-position: 50% 50%;
  background-size: 50%;
  background-repeat: no-repeat; */
}

.aboutYourTherapist-photo {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
}

.aboutYourTherapist-photo > img {
  width: 330px;
}

.aboutYourTherapist-content {
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px 0px;
  font-size: 20px;
  font-family: 'serif';
  justify-self: center;
  color: #4d5665;
  font-size: 1.3rem;
  font-weight: 400;
  word-wrap: break-word;
  line-height: 26px;
}

.qualifications {
  justify-self: center;
  font-size: 1.5rem;
  font-family: "Archivo Black";
  font-weight: 400;
  color: #1A213B;
  word-wrap: break-word;
  margin: 0px 10px 20px 10px;
  text-align: center;
}

@media only screen and (min-width: 800px) {
  .aboutYourTherapist-photo > img {
    width: 450px;
  }
}