.faq {
  display: grid;
  grid-template-columns: 40px auto 40px;
  grid-template-rows: auto;
  grid-template-areas:
    ". faq-title ."
    "faq-questions faq-questions faq-questions";
  background-color: #FFFFFF;
  padding: 20px 0px;
}

.faq-icon {
  grid-area: faq-icon;
  text-align: end;
  color: #1A213B;
  margin-top: 10px;
  margin-left: 20px;
}

.faq-title {
  grid-area: faq-title;
  justify-self: center;
  font-size: 2.5rem;
  font-family: "Archivo Black";
  font-weight: 400;
  color: #1A213B;
  word-wrap: break-word;
  text-align: center;
  margin-bottom: 25px;
}

.faq_questions {
  grid-area: faq-questions;
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 0px 30px;
  background-color: #FFFFFF;
}

.faq-question-title {
  font-weight: 500;
  color: #1A213B;
  font-size: 1.3rem;
}

.faq_question {
  font-size: 1.5rem;
  font-weight: bold;
}

.faq-answer {
  font-size: 1rem;
  line-height: 25px;
}

@media only screen and (min-width: 500px) {
  .faq {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      ". faq-title ."
      "faq-questions faq-questions faq-questions";
  }

  .faq-answer {
    font-size: 1.3rem;
  }
}