.testimonials {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas:
    "testimonial-title"
    "testimonial-1"
    "testimonial-2"
    "testimonial-3"
    "testimonial-4";
  justify-content: center;
  padding: 20px 20px;
  background-color: rgba(56, 54, 54, 0.836);
  background-image: linear-gradient(0deg, rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(../../images/pexels-cottonbro-studio-4098167.jpg);
  background-position: 90% 90%;
}

.testimonial {
  grid-area: testimonial;
  /* font-size: 1.3rem; */
  /* letter-spacing: 1.2px; */
  /* border-radius: 8px; */
  /* background-color: gainsboro; */
  background-color: white;
  margin: 10px 30px;
  padding: 30px 20px;
  /* font-weight: bold; */
  text-align: center;
  cursor: pointer;
}

.testimonial-text {
  margin-top: 20px;
  font-size: 1.5rem;
  font-weight: 600;
  /* font-family: "Archivo Black"; */
  /* margin-bottom: 20px; */
}

.testimonial-icon {
  .MuiSvgIcon-root {
    font-size: 4rem;
    /* color: #4c8666; */
    color: #2f7d52;
  }
}

.testimonial-1 {
  grid-area: testimonial-1;
}

.testimonial-2 {
  grid-area: testimonial-2;
}

.testimonial-3 {
  grid-area: testimonial-3;
}

.testimonial-4 {
  grid-area: testimonial-4;
}

.testimonial-title {
  grid-area: testimonial-title;
  justify-self: center;
  font-weight: bold;
  font-family: '"Playfair Display", serif';
  color: white;
  font-size: 30px;
  margin: 15px 0px;
}

@media only screen and (min-width: 800px) {
  .testimonials {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas:
      "testimonial-title testimonial-title"
      "testimonial-1 testimonial-2"
      "testimonial-3 testimonial-4";
    justify-content: center;
    padding: 10px 10px;
  }  
}