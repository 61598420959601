.contactBook {
  background-color: #70b9835e;
}

.contact-book-title {
  justify-self: center;
  font-size: 2.5rem;
  font-family: "Archivo Black";
  font-weight: 400;
  color: #1A213B;
  word-wrap: break-word;
  margin: 0px 10px;
  text-align: center;
}

.contact-book-sub-text {
  text-align: center;
  margin-top: 20px;
  font-family: 'serif';
  color: #4d5665;
  font-size: 1.3rem;
  font-weight: 400;
}

.servicesCost {
  font-weight: 600;
  font-family: 'serif';
  font-size: 1.6rem;
}

.contactForm {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 1100px;
  width: 80%;
  background: #fff;
  border-radius: 6px;
  padding: 20px 60px 30px 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  margin: 40px 0;
}
.container .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container .content .left-side {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}
.content .left-side::before {
  content: "";
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #afafb6;
}
.content .left-side .details {
  margin: 14px;
  text-align: center;
}
.content .left-side .details i {
  font-size: 30px;
  color: #2f7d52;
  margin-bottom: 10px;
}
.content .left-side .details .topic {
  font-size: 18px;
  font-weight: 500;
}
.content .left-side .details .text-one,
.content .left-side .details .text-two {
  font-size: 20px;
  color: #000;
}

.container .content .right-side {
  width: 75%;
  margin-left: 75px;
}
.content .right-side .topic-text {
  font-size: 23px;
  font-weight: 600;
  color: #2f7d52;
}
.right-side .input-box {
  height: 55px;
  width: 100%;
  margin: 12px 0;
}
.right-side .input-box input,
.right-side .input-box textarea {
  height: 100%;
  width: 96%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #e8f7ea;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}
.right-side .message-box {
  min-height: 110px;
}
.right-side .input-box textarea {
  padding-top: 6px;
}
.right-side .button {
  display: inline-block;
  margin-top: 12px;
}
.right-side .button input[type="submit"] {
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: #2f7d52;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button input[type="submit"]:hover {
  background: #2f7d52;
}

.contactEmailText {
  color: #000;
  text-decoration: none;
  font-size: 1.1rem;
}

.formOptions > label {
  font-size: 1.3rem;
  margin-left: 10px;
}

@media (max-width: 800px) {
  .container {
    height: 100%;
    width: 80%;
  }
  .container .content {
    flex-direction: column-reverse;
  }
  .container .content .left-side {
    width: 100%;
    flex-direction: row;
    margin-top: 40px;
    justify-content: center;
    flex-wrap: wrap;
    align-items: start;
  }
  .container .content .left-side::before {
    display: none;
  }
  .container .content .right-side {
    width: 100%;
    margin-left: 0;
  }

  .contactEmailText {
    font-size: 1.2rem;
  }
}