.App {
  text-align: center;
}

.main {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-template-areas: 
    "header"
    "main"
    "footer";
}

footer {
  align-self: flex-end;
}