html, body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: serif, sans-serif, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: unset;
}