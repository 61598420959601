.footer {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas:
    "footer-iacp"
    "footer-copyright";
  justify-content: center;
  padding: 50px 0px;
  background-color: #4d5665;;
}

.footer-copyright {
  grid-area: footer-copyright;
  color: white;
  justify-self: center;
  text-align: center;
  line-height: 25px;
  margin-top: 20px;
}

.footer-iacp {
  grid-area: footer-iacp;
  justify-self: center;
}

.footer-socials {
  text-align: center;
}

.footer-socials-text {
  color: #FFF;
  font-size: 1.4rem;
  margin-bottom: 15px;
}

.footer-instagram {
  margin-bottom: 10px;
}