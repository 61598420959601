.counsellingAndPsychotherapy {
  background-color: #70b9835e;
}

.counsellingAndPsychotherapy-content {
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px 0px;
  font-size: 20px;
  font-family: 'serif';
  justify-self: center;
  color: #4d5665;
  font-size: 1.3rem;
  font-weight: 400;
  word-wrap: break-word;
  text-align: justify;
  line-height: 26px;
}

.free-text {
  text-decoration: underline;
  background-color: rgba(255, 255, 0, 0.664);
}

.link {
  text-decoration: underline;
  color: #2e333a;
  font-weight: 600;
  cursor: pointer;
}

@media only screen and (min-width: 800px) {
  .counsellingAndPsychotherapy-content {
    margin-left: 50px;
    margin-right: 50px;
  }
}